import * as React from 'react';
import { PageProps } from 'gatsby';
import { PageLayout as Layout } from '../components/Layouts/PageLayout/PageLayout';
import { Seo } from '../components/Seo/Seo';

import { PageTitle } from '../components/ui/pageTitle/pageTitle';

import PageContentHandler from '../components/PageContentHandler';
import pageSpanishData from './data/pageSpanish.json';
import pageSpanishPreviewData from './data/pageSpanishPreview.json';

export const Spanish: React.FC<PageProps> = ({ location }) => {
  if (!location.hostname) return null;

  const pageData =
    location.hostname === 'gatsby.node.tvg.la' ||
    location.hostname === 'localhost'
      ? pageSpanishPreviewData[0]
      : pageSpanishData[0];

  if (!pageData) return null;

  return (
    <Layout className="spanish bg bg-spanish">
      <Seo title="Español" />
      <PageTitle title="Español" />
      <PageContentHandler pageData={pageData} />
    </Layout>
  );
};

/* TODO: Implement simple authentication
export default function Page() {
  return (
    <Router basepath="/spanish">
      <PrivateRoute default component={Spanish} />
    </Router>
  );
}
*/

export default Spanish;
